import React, { Component } from 'react';
import SolutionsMenu from 'components/SolutionsContainer/SolutionsMenu';
import 'components/SolutionsContainer/SolutionsContainer.scss';
import AccountSetupStep from 'components/AccountSetupStep/AccountSetupStep';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import IconArrowLeft from 'static/images/icons/chevron-left.inline.svg';
import IconArrowLeftLight from 'static/images/icons/chevron-left-light.inline.svg';
import IconArrowRight from 'static/images/icons/chevron-right.inline.svg';
import IconArrowRightLight from 'static/images/icons/chevron-right-light.inline.svg';
import BillingProcessFAQ from "components/BillingProcessFAQ/BillingProcessFAQ";
import './ConfigureSystemApp.scss';

import { Modal } from 'react-bootstrap';
import SetupPDF from '../../static/assets/Quick Start Guide (Digital).pdf'
import SetupVideo from '../../static/assets/Resi_Onboarding_AAA.mp4'

import {animateScroll as scroll } from 'react-scroll'

class ConfigureSystemApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItem: 0,
      showNextButton: true,
      show: false
    };
    console.log(this.props.smartAssistantspanelsetup);
  }

 handleClose = () => {this.setState({show:false}); }
handleShow = () => {this.setState({show:true});}

  nextPanel = () => {
    this.setState({ selectedItem: this.state.selectedItem + 1 });
    scroll.scrollToTop();
  };
 
  previousPanel = () => {
    this.setState({ selectedItem: this.state.selectedItem - 1 });
    if(this.state.selectedItem == 0){
      this.props.backButtonSection();
    }
    scroll.scrollToTop();
  };
  

  PanelSetup = ({ panelsetup, quickStartGuideVisible }) => {
    return (
      <>
      <div className="row">
        <div className="col-md-12">
          <div className="A3Subheading PanelHeader">{panelsetup.title}</div>
          <div
            className="A3Text"
            dangerouslySetInnerHTML={{
              __html: panelsetup.description,
            }}
          />
        </div>
      </div>
      {
        quickStartGuideVisible && (
<div className="row">
        <div className="col-md-12">
          <div className="A3ButtonLine GuideBtn">
            <a href={SetupPDF} target="new" className="A3btnLine">
            <i className="ri-download-2-line"></i> Quick Start Guide
            </a>
                </div>
        </div>
      </div>
        )
      }
      
        {panelsetup.steps.map((panel,i) => (
          <div className="row" key={i}>
            <div className="col-md-12">
              <div className="A3Subheading">{panel.title}</div>
              <div
              className="A3Text"
              dangerouslySetInnerHTML={{
                __html: panel.step,
              }}
            />
          </div>
          </div>
        ))}
    </>
    );
  };


  onChangeItem = index => {
    this.setState({ selectedItem: index });
      
    };
  

  prevousPanel = () => {
    this.setState({ selectedItem: this.state.selectedItem - 1 });
  }

  NextSectionVisible = () => {
    this.props.nextSectionVisible();
  }

  render() {
    return (
      <>
      <article className="aaa-solutions">
        <div className="aaa-solutions__container">
          <div className="row">
            <div className="col-md-12">
              <div className="A3Heading">Configure your System &amp; App</div>
            </div>
          </div>
          <div className="A3MenuContainer">
          <SolutionsMenu
            items={this.props.data.items}
            selected={this.state.selectedItem}
            onChange={this.onChangeItem}
          />
          </div>
          <Carousel
            className="aaa-solutions__carousel A3Carousel"
            showThumbs={false}
            showStatus={false}
            showIndicators
            infiniteLoop={false}
            swipeScrollTolerance={100}
            verticalSwipe="natural"
            selectedItem={this.state.selectedItem}
            showArrows={true}
            onChange={this.onChangeItem}
            renderArrowNext={(clickHandler, hasNext) => (
              <button
                disabled={!hasNext}
                className="control-arrow control-next A3ControlNext d-sm-block d-lg-none"
                aria-label="next slide/item"
                onClick={clickHandler}
              >
                <IconArrowRight className="regular" />
                <IconArrowRightLight className="light" />
              </button>
            )}
            renderArrowPrev={(clickHandler, hasPrev) => (
              <button
                disabled={!hasPrev}
                className="control-arrow control-prev A3ControlPrev d-sm-block d-lg-none"
                aria-label="previous slide/item"
                onClick={clickHandler}
              >
                <IconArrowLeft className="regular" />
                <IconArrowLeftLight className="light" />
              </button>
            )}
          >
            {this.state.selectedItem == 0 && (
              <this.PanelSetup
                panelsetup={this.props.panelsetup}
                quickStartGuideVisible = { true }
              ></this.PanelSetup>
            )}
            {this.state.selectedItem == 1 && (

              <>
                <div className="row">
                  <div className="col-12 col-sm-12 col-lg-10">
                    <div className="A3Subheading PanelHeader">
                    Your Alarm Management Account
                    </div>
                  </div>
                </div>
              <AccountSetupStep
                  data={this.props.systemAccountSteps}
                ></AccountSetupStep>
              </>
            )}

            {this.state.selectedItem == 2 && (
              <>
                <div className="row">
                  <div className="col-12 col-sm-12 col-lg-10">
                    <div className="A3Subheading PanelHeader">
                      Setting Up Your Camera: Motion Detection and Field of View
                    </div>
                  </div>
                </div>
                <AccountSetupStep
                  data={this.props.systemCameraSetupSteps}
                ></AccountSetupStep>
              </>
            )}
            {this.state.selectedItem == 3 && (
              <>
                <div className="row">
                  <div className="col-md-12">
                  <div className="A3Subheading PanelHeader">
                    {this.props.systemAppSetupSteps.title}
                  </div>
                  <div
                    className="A3Text"
                    dangerouslySetInnerHTML={{
                      __html: this.props.systemAppSetupSteps.description,
                    }}
                  />
                  <div className="col-sm-12">
              <div className="A3ButtonLine GuideBtn">
                <button
                  type="button"
                  className="A3btnLine"
                  onClick={this.handleShow}
                >
                  <i className="ri-play-circle-line"></i> Watch Setup Video
                </button>
                <Modal show={this.state.show} onHide={this.handleClose} size="xl" centered>
                  <Modal.Body>
                  <Modal.Header closeButton>
                  </Modal.Header>
                    <div className="embed-responsive embed-responsive-16by9 z-depth-1-half">
                      <video 
                        controls
                        preload="metadata"
                        ref={e => this.videoPopup = e}
                      >
                        <source src={SetupVideo} type="video/mp4" />
                      </video>
                    </div>
                  </Modal.Body>
                </Modal>
              </div>
            </div>
                </div>
                </div>
                  <div className="A3Subheading">Setting Up Notifications in Your App</div>
                <AccountSetupStep
                  data={this.props.systemAppSetupSteps}
                ></AccountSetupStep>
              </>
            )}
            {this.state.selectedItem == 4 && (
              <>
                <div className="row">
                <div className="col-md-12">
                  <div className="A3Subheading PanelHeader">
                    {this.props.systemThermostatSteps.title}
                  </div>
                  <div
                    className="A3Text"
                    dangerouslySetInnerHTML={{
                      __html: this.props.systemThermostatSteps.description,
                    }}
                  />
                </div>
                </div>
                <AccountSetupStep
                  data={this.props.systemThermostatSteps}
                ></AccountSetupStep>
                <div className="row">
                  <div className="col-12 col-sm-12 col-lg-10">
                    <div className="A3Subheading PanelHeader">
                      Setting Thermostat Schedules
                    </div>
                  </div>
                </div>
                <AccountSetupStep
                  data={this.props.systemThermostatScheduleSteps}
                ></AccountSetupStep>
              </>
            )}
            {this.state.selectedItem == 5 && (
              <>
                <div className="row">
                  <div className="col-md-12">
                    <div className="A3Subheading PanelHeader">{this.props.smartAssistantspanelsetup.title}</div>
                     
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="A3Subheading">{this.props.smartAssistantspanelsetup.title1}</div>
                      <div
                        className="A3Text"
                        dangerouslySetInnerHTML={{
                          __html: this.props.smartAssistantspanelsetup.step1
                        }}
                      />
                  </div>
                </div>
                
                <div className="row">
                  <div className="col-md-12">
                    <BillingProcessFAQ data={this.props.smartassistantaccordion}></BillingProcessFAQ>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="A3Subheading space">{this.props.smartAssistantspanelsetup.title2}</div>
                      <div
                        className="A3Text"
                        dangerouslySetInnerHTML={{
                          __html: this.props.smartAssistantspanelsetup.step2
                        }}
                      />
                  </div>
                </div>
              </>
            )}
          </Carousel>
        </div>
      </article>

      {this.state.showNextButton && (
          <div className="row">
            <div className="col-md-12">
              <div
                className={
                  this.state.selectedItem != 0 ? 'A3ButtonNext' : 'A3ButtonFill'
                }
              >
                
                {
                  this.state.selectedItem != 0 && (
                    <button
                  type="button"
                  className="A3btnFillGrey"
                  onClick={this.previousPanel}
                >
                  <i className="ri-arrow-left-line"></i> Back
                </button>
                  )
                }
                 {this.state.selectedItem != 5 && (
                <button
                  type="button"
                  className="A3btnFill"
                  onClick={this.nextPanel}
                >
                  Next <i className="ri-arrow-right-line"></i>
                </button>
                 )}

                {this.state.selectedItem == 5 && (
                <button
                  type="button"
                  className="A3btnFill"
                  onClick={this.NextSectionVisible}
                >
                  Next Section <i className="ri-arrow-right-line"></i>
                </button>
                 )}
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default ConfigureSystemApp;
