import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import Select from 'react-select';
// import 'bootstrap/dist/css/bootstrap.min.css';
import './PermitStations.scss'

class PermitStationsData extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      statesArray : [],
      countyArray : [],
      permitInfoArray : [],
      selectedOption: null,
      selectedState: null,
      selectedCounty: null,
      masterCountyArray: [],
      countyID: 0
    }

    // console.log(this.props.data.allBlocksYaml);

    if(this.props.data.allBlocksYaml.nodes[0].states == null) {
      this.props.data.allBlocksYaml.nodes[1].states.map((usstate) => {
        this.state.statesArray.push({ label: usstate.state, value:usstate.stateISO });
      });  
    } else {
      this.props.data.allBlocksYaml.nodes[0].states.map((usstate) => {
        this.state.statesArray.push({ label: usstate.state, value:usstate.stateISO });
      });  
    }
    
    if(this.props.data.allBlocksYaml.nodes[0].states == null) {
      this.props.data.allBlocksYaml.nodes[1].states.map((usstate) => {
        if (usstate.county && usstate.county.length > 0 ) {
          usstate.county.map((uscounty) => {
            this.state.masterCountyArray.push({ label: uscounty.countyName, value: this.state.countyID++, countyStateISO: uscounty.countyStateISO });
            this.state.permitInfoArray.push({
              countyName: uscounty.countyName,
              countyStateISO: uscounty.countyStateISO,
              ordinanceLink: uscounty.ordinanceLink,
              alarmPermitResponsibility: uscounty.alarmPermitResponsibility,
              permitRegistrationLink: uscounty.permitRegistrationLink });
          });
          console.log(this.state.permitInfoArray)
        }
      });
    } else {
      this.props.data.allBlocksYaml.nodes[0].states.map((usstate) => {
        if (usstate.county && usstate.county.length > 0 ) {
          usstate.county.map((uscounty) => {
            this.state.masterCountyArray.push({ label: uscounty.countyName, value: this.state.countyID++, countyStateISO: uscounty.countyStateISO });
            this.state.permitInfoArray.push({
              countyName: uscounty.countyName,
              countyStateISO: uscounty.countyStateISO,
              ordinanceLink: uscounty.ordinanceLink,
              alarmPermitResponsibility: uscounty.alarmPermitResponsibility,
              permitRegistrationLink: uscounty.permitRegistrationLink });
          });
          console.log(this.state.permitInfoArray)
        }
      });
    }
  }
  
  handleChange = selectedState => {
    const countiesByState = this.state.masterCountyArray.filter((sa) => {
      return sa.countyStateISO == selectedState.value;
    });

    this.setState({ countyArray : countiesByState });
    this.setState({selectedState : selectedState});
    this.setState({selectedCounty: null});
  };

  countyChange = selectedCounty => {
    const countyStateISO = this.state.masterCountyArray.filter((mca) => {
      return mca.value == selectedCounty.value;
    })[0].countyStateISO;

    const permitInfo = this.state.permitInfoArray.filter((pi) => {
      return pi.countyName == selectedCounty.label && pi.countyStateISO == countyStateISO;
    });

    if (permitInfo != null && permitInfo.length > 0) {
      this.setState({selectedOption : permitInfo[0]});
    }

    this.setState({selectedCounty : selectedCounty});
    console.log(this.state.selectedOption);
    if(this.state.selectedOption != null){
      console.log(this.state.selectedOption.alarmPermitResponsibility.indexOf("CUST"))
    }
  }

  showText = () => {
    if(this.state.selectedOption && (this.state.selectedOption.alarmPermitResponsibility || this.state.selectedOption.permitRegistrationLink)){
      console.log(this.state.selectedOption.alarmPermitResponsibility);
      if(this.state.selectedOption.alarmPermitResponsibility.indexOf("CUST") != -1 || this.state.selectedOption.alarmPermitResponsibility == ""){
        
        return true;
      }else{
        return false;
      }
    }
  }
  getCountyClass = () => {
    if(this.state.selectedOption && this.state.selectedOption.ordinanceLink && !this.state.selectedOption.alarmPermitResponsibility && !this.state.selectedOption.permitRegistrationLink){
      return "col-md-6 A3CountyResult";
    }
    else if(this.state.selectedOption && (this.state.selectedOption.permitRegistrationLink || this.state.selectedOption.alarmPermitResponsibility)){
      if(this.state.selectedOption && this.state.selectedOption.permitRegistrationLink) {
        return "col-md-4 A3CountyResult";
      }
      else {
        return "col-md-6 A3CountyResult";
      }
    }
    else {
      return "col-md-12 A3CountyResult";
    }
  }

  getOrdinanceClass = () => {
    if(this.state.selectedOption && this.state.selectedOption.ordinanceLink && !this.state.selectedOption.alarmPermitResponsibility && !this.state.selectedOption.permitRegistrationLink){
      return "col-md-6 A3CountyResult";
    }
    else if(this.state.selectedOption && (this.state.selectedOption.permitRegistrationLink || this.state.selectedOption.alarmPermitResponsibility)){
      if(this.state.selectedOption && this.state.selectedOption.permitRegistrationLink) {
        return "col-md-4 A3CountyResult";
      }
      else {
        return "col-md-6 A3CountyResult";
      }
    }
    else {
      return "col-md-12 A3CountyResult";
    }
  }

  render() {
    const { statesArray, countyArray, selectedState, selectedCounty } = this.state;
    return (
        <div className="col-md-12">
        <div className="row">
        <div className="col-md-12">
        <div className="A3Subheading PanelHeader">Locate Permit</div>
        </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <Select
              id='permitStationState'
              value={selectedState}
              onChange={this.handleChange}
              options={statesArray}
            />
            {/* <Select value={selectedOption} options={options}  /> */}
          </div>
          <div className="col-md-6">
            <Select
              id='permitStationCounty'
              value={selectedCounty}
              onChange={this.countyChange}
              options={countyArray}
              allowSelectAll={false}
            />
          </div>
        </div>
        <div className="row">
          {
            this.state.selectedOption && (
              <div className={this.getCountyClass()}>
          <div className="A3County text-center">
            <i className="ri-file-list-2-line A3CountyIcon"></i>
            <i className="ri-award-fill A3CountyBadges"></i>
            <p className="A3Text">
              A permit is <strong>{this.state.selectedOption && (this.state.selectedOption.alarmPermitResponsibility || this.state.selectedOption.permitRegistrationLink) ? "required*": "not required"}</strong> for a home security device in <strong>{ this.state.selectedOption ? this.state.selectedOption.countyName : null }</strong>
            </p>
          </div>
        </div>
            )
          }
        
        {
          this.state.selectedOption && this.state.selectedOption.permitRegistrationLink && (
            <div className="col-md-4 A3CountyResult">
          <div className="A3County text-center">
            <i className="ri-draft-line A3CountyIcon"></i>
            <p className="A3Text">
               
              {
                this.state.selectedOption && (this.state.selectedOption.permitRegistrationLink || this.state.selectedOption.alarmPermitResponsibility) ? <><span>Access the permit application</span> <a href={ this.state.selectedOption.permitRegistrationLink } rel="noreferrer" target="_blank">here</a> </>: " N/A" 
              }
            </p>
          </div>
        </div>
          )
        }
       {
         this.state.selectedOption && this.state.selectedOption.ordinanceLink && (
          <div className={this.getOrdinanceClass()}>
          <div className="A3County text-center">
            <i className="ri-article-line A3CountyIcon"></i>
            <p className="A3Text">
              
              {
                this.state.selectedOption && this.state.selectedOption.ordinanceLink ? <> <span>Read the ordinance </span><a href={ this.state.selectedOption.ordinanceLink } rel="noreferrer" target="_blank">here</a> </> : " N/A"
              }
            </p>
          </div>
        </div>
         )
       } 
        <div className="col-12">
        { this.showText() && (
            <p className="A3PermitWarning"><strong>* Homeowners</strong> are required to register their home security system in <strong>{ this.state.selectedOption ? this.state.selectedOption.countyName : null }</strong></p>
        )
  }
  {!this.showText() && this.state.selectedOption && (this.state.selectedOption.alarmPermitResponsibility || this.state.selectedOption.permitRegistrationLink) && (
          <p className="A3PermitWarning"><strong>* A3 Smart Home</strong> will obtain a home security permit on your behalf from <strong>{ this.state.selectedOption ? this.state.selectedOption.countyName : null }</strong>. Registration will be performed within <strong>30 days</strong> from your install date.</p>
        )
  }
        
        </div>
      </div>
      </div>
      );
  }
}

const PermitStations = props => (
  <StaticQuery
    query={graphql`
      query permitStationQuery {
        allBlocksYaml {
          nodes {
            states {
              state
              stateISO
              county {
                countyName
                countyStateISO
                ordinanceLink
                alarmPermitResponsibility
                permitRegistrationLink
              }
            }
          }
        }
      }
    `}
    render={data => <PermitStationsData data={data} {...props} />}
  />
);

export default PermitStations;