import React from 'react';
import { graphql } from 'gatsby';
import Layout from 'components/Layout/Layout';
import SEO from 'components/SEO/SEO';
import MicrositeHeaderFAQ from 'components/MicrositeHeaderFAQ/MicrositeHeaderFAQ';
import ScrollToTopButton from 'components/ScrollToTopButton';

import ZipGateContainer from 'components/ZipGateContainer/ZipGateContainer';

const MicrositePage = ({ data, location }) => {
  return (
    <Layout location={location} type="welcomekit">
      <SEO
        meta={data.microSiteYaml.metaTags}
        title={data.microSiteYaml.title}
      />
      <MicrositeHeaderFAQ data={data.microSiteYaml}></MicrositeHeaderFAQ>
      <ZipGateContainer location={location} />
      <ScrollToTopButton />
    </Layout>
  );
};

export default MicrositePage;

export const pageQuery = graphql`
  query MicroSitePageQuery {
    microSiteYaml {
      id
      title
      metaTags {
        name
        content
      }
      welcomemessage {
        title
        subtitle
      }
      AAAWebSiteAccess {
        overview
      }
      AccountSteps {
        title
        subtitle
        SetupText
        visibleTitle
        steps {
          step
          title
          img {
            absolutePath
            childImageSharp {
              fluid {
                aspectRatio
                src
                sizes
                base64
                srcSet
              }
            }
          }
          overview
          image
        }
      }
      AddingAutoPaySteps {
        title
        subtitle
        visibleTitle
        steps {
          step
          title
          img {
            absolutePath
            childImageSharp {
              fluid {
                aspectRatio
                src
                sizes
                base64
                srcSet
              }
            }
          }
          overview
          image
        }
      }
      SystemAccountSteps {
        title
        steps {
          step
          title
          img {
            absolutePath
            childImageSharp {
              fluid {
                aspectRatio
                src
                sizes
                base64
                srcSet
              }
            }
          }
          overview
          image
        }
      }
      SystemCameraSetupSteps {
        title
        subtitle
        visibleTitle
        steps {
          step
          title
          img {
            absolutePath
            childImageSharp {
              fluid {
                aspectRatio
                src
                sizes
                base64
                srcSet
              }
            }
          }
          overview
          image
        }
      }
      SystemThermostatScheduleSteps {
        title
        subtitle
        visibleTitle
        steps {
          step
          title
          img {
            absolutePath
            childImageSharp {
              fluid {
                aspectRatio
                src
                sizes
                base64
                srcSet
              }
            }
          }
          overview
          image
        }
      }
      SystemThermostatSteps {
        title
        subtitle
        visibleTitle
        description
        steps {
          step
          title
          img {
            absolutePath
            childImageSharp {
              fluid {
                aspectRatio
                src
                sizes
                base64
                srcSet
              }
            }
          }
          overview
          image
        }
      }
      SystemAppSetupSteps {
        title
        subtitle
        visibleTitle
        description
        steps {
          step
          title
          img {
            absolutePath
            childImageSharp {
              fluid {
                aspectRatio
                src
                sizes
                base64
                srcSet
              }
            }
          }
          overview
          image
        }
      }
      AlarmPermitSteps {
        title
        steps {
          step
          title
        }
      }
      SmartAssistantspanelsetup {
        title
        step1
        title1
        step2
        title2
      }
      billingProcess {
        steps {
          step
          title
          image {
            childImageSharp {
              fluid(maxWidth: 485) {
                ...GatsbyImageSharpFluid_withWebp
              }
              original {
                height
                width
              }
            }
          }
        }
      }
      smartassistantaccordion{
        steps{
          step
          title
        }
      }
      mobileTabsClassNames {
        classes {
          name
        }
      }
      headerTileNames {
        names {
          name
        }
        classes {
          name
        }
      }
      solutions {
        title
        items {
          id
          title
          image {
            childImageSharp {
              fluid(maxWidth: 485) {
                ...GatsbyImageSharpFluid_withWebp
              }
              original {
                height
                width
              }
            }
          }
          tiles {
            title
            description
          }
          products {
            title
            image {
              childImageSharp {
                fluid(maxWidth: 85) {
                  ...GatsbyImageSharpFluid_withWebp
                }
                original {
                  height
                  width
                }
              }
            }
            url
          }
          nonServiceExclude
        }
      }
      panelsetup {
        title
        description
        steps {
          step
          title
        }
      }
    }
  }
`;
