import React, { useState } from 'react';
import './AccountSetupStep.scss';
import Img from 'gatsby-image';
import { Modal } from 'react-bootstrap';

const AllSteps = ({ steps, title, subtitle, SetupText, visibleTitle }) => {
  if (steps) {
    return steps.map((step, i) => (
      <EachStep
        key={i}
        data={step}
        title={title}
        subtitle={subtitle}
        SetupText={SetupText}
        visibleTitle={visibleTitle}
        index={i}
      />
    ));
  }
  return null;
};

const EachStep = ({ data, title, subtitle, visibleTitle, index }) => {
  

  const [showImage, setShowImage] = useState(false);

  const handleImageClose = () => setShowImage(false);
  const handleImageShow = () => setShowImage(true);
  return (
    <>
      {data.overview && (
        <>
          {visibleTitle && (
            <div className="row">
              <div className="col-12 col-sm-12 col-lg-8">
                <div className="A3Heading">{title}</div>
              </div>
            </div>
          )}

          <div className="row">
            <div className="col-sm-12">
              {
                data.title && (
                  <div className="A3Subheading">{data.title}</div>
                )
              }
            
              <div
                className="A3Text"
                dangerouslySetInnerHTML={{
                  __html: data.step,
                }}
              />
            </div>
            {/* <div className="col-12 col-sm-12 col-lg-4">
              <div className="A3ButtonLine">
                <button
                  type="button"
                  className="A3btnLine video-btn"
                  onClick={handleShow}
                >
                  <i className="ri-play-circle-line"></i> Watch Setup Video
                </button>
                <Modal show={show} onHide={handleClose} size="xl" centered>
                  <Modal.Body>
                    <div className="embed-responsive embed-responsive-16by9 z-depth-1-half">
                      <iframe
                        className="embed-responsive-item"
                        src={SetupVideo}
                      ></iframe>
                    </div>
                  </Modal.Body>
                </Modal>
              </div>
            </div> */}
          </div>
        </>
      )}

      {!data.overview && (
        <>
          {!data.overview &&
            index == 1 &&
            subtitle != 'Your Alarm Management Account' && (
              <div className="row">
                <div className="col-md-12">
                </div>
              </div>
            )}

          <div className="row">
            
            {!data.image && (
              <>
                <div className="col-sm-12">
                  <div className="A3Subheading">{data.title}</div>
                <div
                className="A3Text"
                  dangerouslySetInnerHTML={{
                    __html: data.step,
                  }}
                />
                
                </div>
              </>
            )}

            {data.image && (
              <>
                <div className="col-12 col-sm-12 col-lg-8">
                  <div className="A3Subheading">{data.title}</div>
                  <div
                  className="A3Text"
                    dangerouslySetInnerHTML={{
                      __html: data.step,
                    }}
                  />
                </div>
                <div className="col-12 col-sm-12 col-lg-4">
                  <a href="" onClick={handleImageShow}>
                  <Img
                    className="A3Image"
                    fluid={data.img.childImageSharp.fluid}
                    loading="eager"
                    fadeIn={false}
                  />
                  </a>
                  <div className="A3ClickText">
                  <button
                  type="button"
                  onClick={handleImageShow}
                >
                  Click to enlarge <i className="ri-zoom-in-line"></i>
                </button>
                </div>
                </div>
                
                <Modal
                    show={showImage}
                    onHide={handleImageClose}
                    size="lg"
                    centered
                  >
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                      <Img
                        className="img-fluid"
                        fluid={data.img.childImageSharp.fluid}
                        loading="eager"
                        fadeIn={false}
                      />
                    </Modal.Body>
                  </Modal>
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

const AccountSetupStep = ({ data }) => {
  return (
    <>
      <AllSteps
        steps={data.steps}
        title={data.title}
        subtitle={data.subtitle}
        SetupText={data.SetupText}
        visibleTitle={data.visibleTitle}
      ></AllSteps>
    </>
  );
};

export default AccountSetupStep;

